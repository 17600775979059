import { Grid, IconButton, Snackbar } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Alert, Color } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import FooterButton from '../components/footerButton';
import { useFormContext } from '../components/formContext';
import InteractionDetailCard from '../components/interactionDetailCard';
import {
  InteractionSubmitResponse,
  InteractionsContextValue,
  useInteractionsContext,
} from '../components/interactionsContext';
import Layout from '../components/layout';
import useAuthRouteProtection from '../hooks/useAuthRouteProtection';
import { Interaction, navigateToForm } from '../services/interactions';
import { FORM_TYPE_NAME, FORM_TYPE_TITLE, FormType } from '../services/lists';
import { BaseProps } from '../typings/types';

interface PureActiveInteractionsProps {
  type: FormType;
  interactions?: Interaction[];
  submit: InteractionsContextValue['submitInteraction'];
  isSubmitting: boolean;
}

export const PureActiveInteractions: React.FC<PureActiveInteractionsProps> = ({
  type,
  interactions,
  submit,
  isSubmitting,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>();
  const [snackbarSeverity, setSnackbarSeverity] = useState<Color>();

  const handleSnackbarClose = (): void => setOpenSnackbar(false);

  const handleSubmit = (
    type: FormType,
    interaction: Interaction
  ): Promise<void> => {
    setOpenSnackbar(false);

    return submit(type, interaction, (response): void => {
      setOpenSnackbar(true);

      switch (response) {
        case InteractionSubmitResponse.SUCCESS:
        case InteractionSubmitResponse.NETWORK_ERROR:
          setSnackbarMessage(`${FORM_TYPE_NAME[type]} submitted. Thank you.`);
          setSnackbarSeverity('success');
          break;
        case InteractionSubmitResponse.ERROR_WHEN_SUBMITTING:
          setSnackbarMessage(
            'Error while submitting interaction to the server'
          );
          setSnackbarSeverity('error');
          break;
      }
    });
  };

  return (
    <Layout
      noFooter={!interactions}
      wrapper={false}
      title={FORM_TYPE_TITLE[type]}
    >
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        ContentProps={{
          'aria-describedby': 'complete-message',
        }}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>,
        ]}
      >
        <Alert severity={snackbarSeverity} onClose={handleSnackbarClose}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Grid
        direction="row"
        alignItems="center"
        justify="space-around"
        container
        spacing={2}
      >
        {interactions
          ? interactions.map((i, index) => (
              <InteractionDetailCard
                key={index}
                type={type}
                interaction={i}
                submit={handleSubmit}
                isSubmittingInteraction={isSubmitting}
              />
            ))
          : ''}
      </Grid>

      <FooterButton
        color="secondary"
        disabled={isSubmitting}
        onClick={(): void => navigateToForm(type)}
      >
        NEW
      </FooterButton>
    </Layout>
  );
};

const ActiveInteractions: React.FC<BaseProps> = ({
  location: { pathname, hash },
}) => {
  const extractFormType = (hash: string): FormType => {
    return hash.substring(1) as FormType;
  };
  const type = extractFormType(hash);

  const { clearForm } = useFormContext();
  useEffect(() => {
    // Don't let stale form data survive
    clearForm();
  }, []);

  useAuthRouteProtection(pathname);

  const {
    getInteractions,
    submitInteraction,
    isSubmitting,
  } = useInteractionsContext();

  return (
    <PureActiveInteractions
      type={type}
      interactions={getInteractions(type)}
      submit={submitInteraction}
      isSubmitting={isSubmitting}
    />
  );
};

export default ActiveInteractions;
