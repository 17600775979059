import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Interaction, navigateToForm } from '../services/interactions';
import { FormType } from '../services/lists';
import { tallyTotal } from './tallyTracker';
import React, { useState } from 'react';
import moment from 'moment';
import { Pencil } from '../icons';

const useStyles = makeStyles((theme: Theme) => {
  return {
    cardGrid: {
      minWidth: '17rem',
    },
    card: {
      backgroundColor: theme.palette.primary.dark,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  };
});

const getInteractionAttributes = (
  type: FormType,
  interaction: Interaction
): {
  name?: string;
  tallyDescription: string;
  extra: string[];
} => {
  const { name, tally } = interaction;
  const extra: string[] = [];

  const tallyCount = tallyTotal(tally);
  const tallyDescription =
    tallyCount === 0
      ? '-'
      : tallyCount === 1
      ? 'Individual'
      : `Group of ${tallyCount}`;

  const addExtra = (property: keyof Interaction, prefix: string): void => {
    if (!!interaction[property])
      extra.push(`${prefix}: ${interaction[property]}`);
  };

  switch (type) {
    case FormType.Prevention:
      addExtra('condition', 'Condition');
      break;
    case FormType.Community:
      addExtra('actionType', 'Activity');
      break;
    case FormType.PlannedMedical:
    case FormType.Transport:
      addExtra('pickupLocation', 'Pickup');
      addExtra('dropOffLocation', 'Dropoff');
      break;
    case FormType.Referral:
    case FormType.Yes:
      break;
    case FormType.Collaboration:
      addExtra('subtype', 'Type');
      break;
    case FormType.ChildrenFamilySupport:
      addExtra('subtype', 'Type');
      addExtra('reasons', 'For');
      break;
    default:
      break;
  }

  return { name, tallyDescription, extra };
};

export interface InteractionDetailCardProps {
  type: FormType;
  interaction: Interaction;
  submit: (type: FormType, interaction: Interaction) => Promise<void>;
  isSubmittingInteraction: boolean;
}

const InteractionDetailCard: React.FC<InteractionDetailCardProps> = ({
  type,
  interaction,
  submit,
  isSubmittingInteraction,
}) => {
  const classes = useStyles();
  const [isClicked, setIsClicked] = useState(false);

  const submitInteraction = (): void => {
    setIsClicked(true);
    submit(type, interaction).finally(() => {
      setIsClicked(false);
    });
  };

  const { name, tallyDescription, extra } = getInteractionAttributes(
    type,
    interaction
  );

  return (
    <Grid item md={6} xs={12} className={classes.cardGrid}>
      <Card className={classes.card}>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography variant="h6" component="h6">
                {moment(interaction.timeOccurred).calendar(null, {
                  sameDay: '[Today] HH:mm',
                  nextDay: '[Tomorrow] HH:mm',
                  nextWeek: 'dddd HH:mm',
                  lastDay: '[Yesterday] HH:mm',
                  lastWeek: '[Last] dddd HH:mm',
                  sameElse: 'DD/MM/YYYY',
                })}
              </Typography>
              <Typography variant="h6" component="h6">
                {interaction.id}
              </Typography>
            </Grid>
          }
        />
        <CardContent>
          <Grid container direction="row">
            <Grid item xs={6}>
              {extra.map((attribute, idx) => (
                <Typography key={idx} component="p" gutterBottom>
                  {attribute}
                </Typography>
              ))}
            </Grid>
            <Grid item xs={6}>
              {name && (
                <Typography component="p" gutterBottom align="right">
                  {name}
                </Typography>
              )}
              <Typography component="p" gutterBottom align="right">
                {tallyDescription}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Button
                role="edit"
                variant="outlined"
                color="secondary"
                onClick={(): void => navigateToForm(type, interaction.id)}
                disabled={isClicked || isSubmittingInteraction}
              >
                <Pencil />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                disabled={isClicked || isSubmittingInteraction}
                color="secondary"
                onClick={submitInteraction}
              >
                {isClicked && isSubmittingInteraction ? (
                  <>
                    <CircularProgress />
                    <Typography variant="srOnly">SUBMITTING</Typography>
                  </>
                ) : (
                  <>Complete</>
                )}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default InteractionDetailCard;
